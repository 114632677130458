import styled, { css } from 'styled-components';
import { colors } from '../styles/colors';
import { Button, Card } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';

export const InputLabelCustom = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

export const InputWrapper = styled.div`
  margin: 1rem 0 1rem;
`;

export const SkillItem = styled.div<{ $selected: boolean }>`
  padding: 8px 16px;
  margin: 4px;
  font-size: 12px;
  border-radius: 16px;
  cursor: pointer;

  color: ${(props) => (props.$selected ? colors.white : colors.brand)};

  background-color: ${(props) =>
    props.$selected ? colors.brand : colors.white};

  ${({ $selected }) =>
    $selected &&
    css`
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      grid-column-gap: 3px;
      padding-right: 8px;
    `}
`;

export const CardBlue = styled(Card)`
  background-color: ${colors.cardLightBlue};
`;

export const FormCard = styled(CardBlue)`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: minmax(100px, 430px);
  grid-gap: 16px;
  justify-content: center;
`;

export const OptionSelector = styled(Button)<
  ButtonProps & { $selected: boolean }
>`
  height: auto;
  margin: 2px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 14px;

  ${(props) =>
    props.$selected &&
    css`
      margin: 0;
      border: 3px solid #093c8f;

      background-color: #cce2ff;

      &:hover {
        margin: 0;
        border: 3px solid #093c8f;
        background-color: #cce2ff;
      }
    `}
`;
